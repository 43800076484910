import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import swal from "sweetalert";
import config from "../inc/config";
import { getCookie } from "../inc/cookies";

class CreateAppointmentModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      serviceType: [
        {id:1, type: 'Tekli'},
        {id:2, type: 'Çoklu (önerilir)'}
      ],

      carAmount: [
        {count: '1'},
        {count: '2'},
        {count: '3'},
        {count: '4'},
        {count: '5'}
      ],

      cities:[],

      postInfo: {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        brand: '',
        model: '',
        pickupDate: '',
        comment: '',
        pickupCity: '',
        pickupAddress: '',
        dropoffCity: '',
        dropoffAddress: '',
        /* multiVehicle: '1', */
        serviceType: 0
      },
      pickUpDate: '',
      pickUpHour: '',
      combinedDateTime: ''
    };
  }

  componentDidMount() {
    this.getCities()
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  getCities() {

    const requestOptions = {
      method: 'GET',
    };

    fetch(config.restApi + 'cities/tr/'+ 1, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, cities: responseData.cities});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        }, () => swal({dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Tamam",})
      );
  }

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.postInfo.firstname) {
      infoMessage = 'İsim boş geçilemez !'
    } else if (!this.state.postInfo.lastname) {
      infoMessage = 'Soyisim boş geçilemez !'
    } else if (!this.state.postInfo.email) {
      infoMessage = 'Email boş geçilemez !'
    } else if (!this.state.postInfo.phoneNumber) {
      infoMessage = 'Telefon numarası boş geçilemez !'
    } else if (!this.state.postInfo.brand) {
      infoMessage = 'Marka boş geçilemez !'
    } else if (!this.state.postInfo.model) {
      infoMessage = 'Model boş geçilemez !'
    } else if (!this.state.postInfo.pickupCity) {
      infoMessage = 'Teslim alınacak il boş geçilemez !'
    } else if (!this.state.postInfo.pickupAddress) {
      infoMessage = 'Teslim alınacak adres boş geçilemez !'
    } else if (!this.state.postInfo.dropoffCity) {
      infoMessage = 'Teslim edilecek il boş geçilemez !'
    } else if (!this.state.postInfo.dropoffAddress) {
      infoMessage = 'Teslim edilecek adres boş geçilemez !'
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {

      /* const selectedPickUpDate = new Date(this.state.postInfo.pickUpDate).getTime();
      const currentDate = new Date().getTime();

      if (selectedPickUpDate < currentDate) {
        swal({
          dangerMode: true,
          icon: 'warning',
          title: 'Uyarı',
          text: 'Seçilen tarihler mevcut tarihten önce olamaz!',
          buttons: 'Tamam'
        });
      } else { */
        const requestOptions = {
          method: 'POST',
          headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
          body: JSON.stringify(this.state.postInfo)
        };

        fetch(config.restApi + 'towRequest/tr', requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              this.setState({postInfo: ''});
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Bilgiler başarıyla eklendi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/tows'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    /* } */
  }

  handlePickUpCitySelect = (e) => {
    const selectedCityName = e.target.value;
    this.setState({ postInfo: { ...this.state.postInfo, pickupCity: selectedCityName}});
  }

  handleDropOffCitySelect = (e) => {
    const selectedDropCityName = e.target.value;
    this.setState({ postInfo: { ...this.state.postInfo, dropoffCity: selectedDropCityName}});
  }

  handleSelectCarAmount = (e) => {
    const selectedCarAmount = e.target.value;
    this.setState({ postInfo: { ...this.state.postInfo, multiVehicle: selectedCarAmount}});
  }

  handleServiceType = (e) => {
    const selectedServiceType = parseInt(e.target.value)
    this.setState({ postInfo: { ...this.state.postInfo, serviceType: selectedServiceType}});
  }

  handlePhoneRegex = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  handleFullNameRegex = (event) => {
    if (/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g.test(event.key)) {
      event.preventDefault();
    }
  }

  handleDateChange = (event) => {
    const newDate = event.target.value;
    this.setState(({ pickUpDate: newDate}), this.combineDateTime);
  };

  handleHourChange = (event) => {
    const newHour = event.target.value;
    this.setState(({ pickUpHour: newHour}), this.combineDateTime);
  };

  combineDateTime = () => {
    if (this.state.pickUpDate && this.state.pickUpHour) {
      const combinedDateTime = new Date(`${this.state.pickUpDate}T${this.state.pickUpHour}`);
      const pickUpDate = combinedDateTime.toISOString();
      this.setState({postInfo: {...this.state.postInfo, pickupDate: pickUpDate}, combinedDateTime});
    }
  };

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-black d-flex justify-content-between pr-2">
              <h4>YENİ ÇEKİCİ BAŞVURUSU</h4>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-sm-6 b-r mb-0">
                  <div className="d-flex">
                    <i className="fa fa-user icon mr-2 mt-1 text-orange"></i>
                    <h4 className="m-t-none m-b"><strong>BAŞVURU SAHİBİ</strong></h4>
                  </div>
                  <form role="form">
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>AD</label>
                        <input className="form-control b-r-sm" placeholder="Adınız*" onChange={event => this.setState({ postInfo: {...this.state.postInfo, firstname: event.target.value}})} onKeyPress={(e) => this.handleFullNameRegex(e)} type="text"/>
                      </div>
                      <div className="col-6 form-group">
                        <label>SOYAD</label>
                        <input className="form-control b-r-sm" placeholder="Soyadınız*" onChange={event => this.setState({ postInfo: {...this.state.postInfo, lastname: event.target.value}})} onKeyPress={(e) => this.handleFullNameRegex(e)} type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>E-POSTA</label>
                        <input className="form-control b-r-sm" placeholder="E-posta Adresiniz*" onChange={event => this.setState({ postInfo: {...this.state.postInfo, email: event.target.value}})} type="text"/>
                      </div>
                      <div className="col-6 form-group">
                        <label>TELEFON</label>
                        <input className="form-control b-r-sm" maxLength={10}  placeholder="Telefon Numaranız*" onChange={event => this.setState({ postInfo: {...this.state.postInfo, phoneNumber: event.target.value}})} onKeyPress={(e) => this.handlePhoneRegex(e)} type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>Marka</label>
                        <input className="form-control b-r-sm" placeholder="Marka*" onChange={event => this.setState({ postInfo: {...this.state.postInfo, brand: event.target.value}})} type="text"/>
                      </div>
                      <div className="col-6 form-group">
                        <label>Model</label>
                        <input className="form-control b-r-sm" maxLength={10}  placeholder="Model*" onChange={event => this.setState({ postInfo: {...this.state.postInfo, model: event.target.value}})} type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <i className="fa fa-calendar icon mr-2 mt-1 text-orange"></i>
                      <h4 className="m-t-none m-b"><strong>RANDEVU TARİHİ VE ZAMANI</strong></h4>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>Tarih</label>
                        <input className="form-control b-r-sm" onChange={this.handleDateChange} type="date" />
                      </div>
                      <div className="col-6 form-group">
                        <label>Saat</label>
                        <input className="form-control b-r-sm" onChange={this.handleHourChange} type="time" />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex">
                    <i className="fa fa-user icon mr-2 mt-1 text-orange"></i>
                    <h4 className="m-t-none m-b"><strong>ADRES BİLGİLERİ</strong></h4>
                  </div>
                  <form role="form">
                    <div className="d-flex">
                      <div className="col-6 form-group pl-0 m-0">
                        <label>Teslim Alınacak İl</label>
                        <select className="form-control b-r-sm pt-0 pb-0" name="account" onChange={event => this.handlePickUpCitySelect(event)}>
                          <option value="" selected>Seçiniz</option>
                          {this.state.cities.map((city, index) =>
                            <option key={index} value={city.name}>{city.name}</option>
                          )}
                        </select>
                      </div>
                      <div className="col-6 form-group mr-3">
                        <label>Teslim Alınacak Adres</label>
                        <input className="form-control b-r-sm" placeholder="Teslim Alınacak Adres" onChange={event => this.setState({ postInfo: {...this.state.postInfo, pickupAddress: event.target.value}})} type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 form-group pl-0 m-0">
                        <label>Teslim Edilecek İl</label>
                        <select className="form-control m-b b-r-sm pt-0 pb-0" name="account" onChange={event => this.handleDropOffCitySelect(event)}>
                          <option value="" selected>Seçiniz</option>
                          {this.state.cities.map((city, index) =>
                            <option key={index} value={city.name}>{city.name}</option>
                          )}
                        </select>
                      </div>
                      <div className="col-6 form-group mr-3">
                        <label>Teslim Edilecek Adres</label>
                        <input className="form-control b-r-sm" placeholder="Teslim Edilecek Adres" onChange={event => this.setState({ postInfo: {...this.state.postInfo, dropoffAddress: event.target.value}})} type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-12 form-group pl-0 m-0">
                        <label>Not</label>
                        <textarea className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ postInfo: {...this.state.postInfo, comment: event.target.value}})} placeholder="Not" type="text" />
                      </div>
                    </div>
                  </form>
                  <div className="d-flex">
                    <i className="fa fa-car icon mr-2 mt-1 text-orange"></i>
                    <h4 className="m-t-none m-b"><strong>ÇEKİCİ HİZMETİ TÜRÜ</strong></h4>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-10">
                    {this.state.serviceType.map((service, index) =>
                      <div className="align-middle" key={index}>
                        <div className="d-flex">
                          <label className="d-flex cursor">
                            <input className="mr-1" type="radio" value={service.id} name="serviceType" onChange={(e) => this.handleServiceType(e)}/>
                            {service.type}
                          </label>
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end pr-2 text-black">
                    <button className="btn border btn-darkgray text-white" onClick={this.saveOnClick}>Gönder</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function CreateAppointment(props) {
  return (
    <CreateAppointmentModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></CreateAppointmentModule>
  );
}
