import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import config from "../inc/config";
import { getCookie } from "../inc/cookies";
import { isoToDateTime } from "../inc/helper";
import AddressModal from "../components/AddressModal";

class TowsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      id: 0,

      towRequests: [],
      dropoffAddress: '',
      pickupAddress: '',
      comment: '',
      isOpenAddressModal: false,
      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10,
      
    };
  }
  

  componentDidMount() {
    this.getRowRequests(this.state.currentPage)
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  
  getRowRequests(currentPage) {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `towRequests/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              const currenttowRequests = responseData.towRequests.slice(startIndex, endIndex);
              this.setState({isLoading: false, towRequests: currenttowRequests, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }
  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getRowRequests(this.state.currentPage))
  }

  handleAddressDetail = (id, comment, dropoffAddress, pickupAddress) => {
    this.setState({comment: comment, dropoffAddress: dropoffAddress, id: id, isOpenAddressModal: true, pickupAddress: pickupAddress})
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
        <div className="ibox">
        <div className="ibox-title text-black d-flex justify-content-between pr-2">
          <h4>ÇEKİCİ BAŞVURULARI</h4>
        </div>
        <div className="table-responsive-lg mb-5">
          <table className="table table-bordered text-center">
            <thead>
              <tr className="text-black">
                <th>Başvuru No</th>
                <th>Randevu Tarihi</th>
                <th>Adı Soyadı</th>
                <th>Marka<br/>Model</th>
                <th>Alındığı İl</th>
                <th>Bırakılacağı İl</th>
                <th>Adres</th>
              </tr>
            </thead>
            <tbody>
            {this.state.towRequests.length === 0 ? (
              <tr>
                <td colSpan="6">Kayıtlı Çekici Başvurusu Bulunmamaktadır</td>
              </tr>
            ) : (
              this.state.towRequests.map((tow, index) => (
                <tr key={index}>
                  <td className="align-middle">{tow.id}</td>
                  <td className="align-middle">{tow.pickupDate ? isoToDateTime(tow.pickupDate) : '-'}</td>
                  <td className="align-middle">{tow.fullname}</td>
                  <td className="align-middle">{tow.brand}<br/><b>{tow.model}</b></td>
                  <td className="align-middle">{tow.pickupCity}</td>
                  <td className="align-middle">{tow.dropoffCity}</td>
                  <td className="align-middle"><button className="btn btn-outline btn-darkgray text-white text-center mb-1" onClick={() => this.handleAddressDetail(tow.id, tow.comment, tow.dropoffAddress, tow.pickupAddress)} title="Randevu Detay"><i className="fa fa-eye"></i></button></td>
                </tr>
              ))
            )}
            </tbody>
          </table>
          <AddressModal comment={this.state.comment} dropoffAddress={this.state.dropoffAddress} isOpen={this.state.isOpenAddressModal} modalTitle="Adres Bilgileri" onRequestClose={() => this.setState({isOpenAddressModal: false})} pickupAddress={this.state.pickupAddress} tows={this.state.towRequests}/>
        </div>
        <ReactPaginate
          previousLabel={"Geri"}
          nextLabel={"İleri"}
          breakLabel={"..."}  
          pageCount={this.state.pageCount}
          marginPagesDisplayed={1}
          onPageChange={page => this.handlePageClick(page)}
          pageRangeDisplayed={2}
          containerClassName={'pagination'}
          activeClassName={'activePage'}
        />
      </div>
    );
  }
}

export default function Tows(props) {
  return (
    <TowsModule
      navigate={useNavigate()}
      searchParams={useSearchParams()[0]}
    ></TowsModule>
  );
}
