import React from 'react';
import {useNavigate, Link} from 'react-router-dom';

import swal from 'sweetalert';
import Images from '../utils/Images'

import clientData from '../inc/clientData';
import config from '../inc/config';
import {removeCookie} from '../inc/cookies';

class UserFrameModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      navigationList: [
        {id: 1, icon: 'fa-home', title: 'Hoşgeldiniz', items: [], url: '/'},
        {id: 2, icon: 'fa-calendar', title: 'Çekici Başvuruları', items: [], url: '/tows'},
        {id: 3, icon: 'fa-list-alt', title: 'Yeni Başvuru', items: [], url: '/create-tow'},
        {id: 4, icon: 'fa-id-card', title: "Bana Özel'e git", items: [], url: `https://banaozel.${config.host}`},
        {id: 5, icon: 'fa-vcard', title: "Satış Garanti Anasayfa", items: [], url: `https://${config.host}`},
        {id: 6, icon: 'fa-phone', title: 'Müşteri Hizmetleri', items: [], url: '/customer-service'}
      ],

      memberInfo: {
        firstname: '',
        lastname: '',
        username: '',
        image: null
      }
    }

    this.userLogoutOnClick = this.userLogoutOnClick.bind(this);
  }

  

  componentDidMount() {
    this.getSessionInfo();

    document.querySelector(".navbar-minimalize").addEventListener("click", this.menuToggle);
  }

  componentWillUnmount() {
    document.querySelector(".navbar-minimalize").removeEventListener("click", this.menuToggle);
  }
  
  menuToggle() {
    document.body.classList.toggle("mini-navbar");
  }

  getSessionInfo() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    fetch(config.restApi + 'memberInfo', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({isLoading: false, memberInfo: responseData.memberInfo});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet basadadğlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  userLogoutOnClick(event) {
    event.preventDefault();

    swal({
      dangerMode: true,
      icon: 'info',
      title: 'Onayla',
      text: 'Oturumu kapatmak istediğinize emin misiniz ?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then((value) => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'logout', requestOptions)
        .then(response => response.json())
        .then(responseData => responseData.status === 200 && removeCookie('sessionHash') && window.location.replace('/'), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div id="wrapper">
        <nav className="navbar-default navbar-static-side navbar-border text-light-gray">
          <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu">
              <li className="nav-header text-center text-light-gray">
                <div className="dropdown profile-element">
                  <img alt="userImage" className="rounded-circle w-50 h-50" src={Images.userIcon} />
                  <h4 className="block font-bold text-white mt-4">{this.state.memberInfo.firstname} {this.state.memberInfo.lastname}</h4>
                  <span className="text-white block">{this.state.memberInfo.username}</span>
                </div>
              </li>
              {this.state.navigationList.map((navigationListInfo) =>
                <li key={navigationListInfo.id}>
                  <Link to={navigationListInfo.url}><i className={"text-white fa " + navigationListInfo.icon}></i> <span className="nav-label text-white font-bold">{navigationListInfo.title}</span> {navigationListInfo.items.length > 0 ? <span className="fa fa-caret-down float-right"></span> : null}</Link>
                  {navigationListInfo.items.length > 0 ?
                    <ul className="nav nav-second-level">
                      {navigationListInfo.items.map(itemInfo => <li key={itemInfo.id}><Link to={itemInfo.url}><i className={"fa " + itemInfo.icon}></i> {itemInfo.title}</Link></li>)}
                    </ul>
                  : null}
                </li>
              )}
            </ul>
          </div>
        </nav>
        <div className="bg-mainpage" id="page-wrapper">
          <div className="row border-bottom d-block">
            <nav className="navbar navbar-static-top bg-gray p-1">
              <div className="navbar-header">
                <Link className="navbar-minimalize minimalize-styl-2 btn navbar-button-c2" to="#"><i className="fa fa-bars"></i></Link>
                <Link className="header-logo" to="/"><img alt="Logo" className="ml-3 mt-3 w-0 h-0" src={Images.logo} /></Link>
              </div>
              <ul className="nav navbar-top-links navbar-right">
                <li>
                  <button className="btn btn-md d-none d-sm-block logout font-bold border-0" onClick={this.userLogoutOnClick} title="Oturumu Kapat"><i className="fa fa-sign-out"></i> Oturumu Kapat</button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="wrapper wrapper-content px-0">
            {this.props.children}
          </div>
          <div className="footer">
            <div className="text-center text-dark d-sm-none">
              <strong>Copyright</strong> SG GARANTİ BİLİŞİM &copy; 2019 - 2024
            </div>
            <div className="float-right text-dark d-none d-sm-block">
              <strong>Copyright</strong> SG GARANTİ BİLİŞİM &copy; 2019 - 2024
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function UserFrame(props) {
  return (
    <UserFrameModule navigate={useNavigate()}>{props.children}</UserFrameModule>
  )
}